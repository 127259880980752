import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import DefaultBusinessContext from '../../business/BusinessContext';
import { className, extractTechnicalError, TechnicalErrorDetails } from '../../util';
import ExpectedError from '../ExpectedError';
import RequiredFieldError from '../RequiredFieldError';
import TechnicalError from '../TechnicalError';

const ForgotPassword = () => {
  const context = useContext(DefaultBusinessContext);
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [technicalError, setTechnicalError] = useState(null as unknown as TechnicalErrorDetails);
  const [validated, setValidated] = useState(false);
  const initPasswordReset = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setValidated(true);
    setError('');
    setTechnicalError(null as unknown as TechnicalErrorDetails);
    setSuccess(false);
    if (!event.currentTarget.checkValidity()) {
      return;
    }
    try {
      await context.initPasswordReset(email);
      setEmail('');
      setValidated(false);
      setSuccess(true);
    } catch (e) {
      if (403 === e.status) {
        setError('Niepoprawne hasło');
      } else {
        setTechnicalError(await extractTechnicalError(e));
      }
    }
  };

  return (
    <div className="container forgot-password">
      <div className="row">
        <div className="col-12 col-md-6 offset-md-3 mt-5">
          <h3>Nie pamiętam hasła</h3>
          <hr className="my-4"/>
          {technicalError && <div className="col-12"><TechnicalError error={technicalError}/></div>}
          <form onSubmit={initPasswordReset} noValidate className={className({ 'was-validated': validated })}>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input type="email" className="form-control" id="email" value={email} onChange={e => setEmail(e.target.value)} required/>
              <RequiredFieldError message={'Wymagany poprawny email'}/>
            </div>
            {error && (<ExpectedError message={error}/>)}
            {success && (<div className="alert alert-success text-center py-1" role="alert">
              Na podany adres wysłaliśmy wiadomość z linkiem do zmiany hasła. Sprawdź skrzynkę pocztową. </div>)}
            <button type="submit" className="btn btn-primary rounded-0 w-100 my-3">Zresetuj hasło</button>
            <div className="text-center mt-3">
              <span className="text-dark mr-1">lub</span>
              <Link to="/login">Zaloguj się</Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
