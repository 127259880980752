import React from 'react';
import videojs from 'video.js'
import 'video.js/dist/video-js.css'
import 'videojs-resolution-switcher/lib/videojs-resolution-switcher.css'
import { VideoSource } from '../../business/DataModel';

type VideoProps = {
  poster?: string,
  resolution?: string,
  sources: VideoSource[],
  onEnded?: () => void,
  onResolutionChange?: (resolution: string) => void
};

const mapVideoSource = (sources: VideoSource[]) => (sources && sources.map(({ resolution, videoUrl }) => ({
  src: videoUrl,
  res: resolution,
  type: 'video/mp4',
  label: `${resolution}`
}))) || [];

class Video extends React.Component<VideoProps> {
  private videoNode: any;
  private player: any;

  componentDidMount() {
    const { onEnded, onResolutionChange, poster, resolution, sources } = this.props;
    if (window) {
      // @ts-ignore
      window.videojs = videojs;
    }
    require('videojs-resolution-switcher');
    const options = {
      controls: true,
      playbackRates: [1, 1.25, 1.5, 1.75, 2],
      poster,
      sources: mapVideoSource(sources),
      preload: 'none',
      plugins: {
        videoJsResolutionSwitcher: {
          default: resolution || 'high',
          dynamicLabel: true
        }
      }
    };
    this.player = videojs(this.videoNode, options);
    onEnded && this.player.on('ended', onEnded);
    onResolutionChange && this.player.on('resolutionchange', () => onResolutionChange(this.player.currentResolution().label));
  }

  componentDidUpdate() {
    if (this.player) {
      this.player.off('ended');
      if (this.props.onEnded) {
        this.player.on('ended', this.props.onEnded);
      }
      if (this.props.sources) {
        this.player.updateSrc(mapVideoSource(this.props.sources));
      }
    }
  }

  componentWillUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  }

  render() {
    return (
      <div className="embed-responsive embed-responsive-16by9">
        <video ref={node => this.videoNode = node} className="video-js embed-responsive-item"/>
      </div>
    )
  }
}

export default Video;
