import React, { useContext, useEffect, useState } from 'react';

import { createTechnicalErrorHandler, formatDate, formatPrice, formatStatus, TechnicalErrorDetails } from '../../util';
import DefaultBusinessContext from '../../business/BusinessContext';
import { Link, useParams } from 'react-router-dom';
import * as DataModel from '../../business/DataModel';
import { Order } from '../../business/DataModel';
import TechnicalError from '../TechnicalError';
import Loading from '../Loading'

type OrdersTableProps = { orders: Order[] };
const OrdersTable = ({ orders }: OrdersTableProps) => {
  if (!orders.length) {
    return (<div className="alert alert-secondary" role="alert">
      <div className="text-center">Jeszcze nic nie zamówiłeś</div>
    </div>);
  }
  return (
    <div className="table-responsive">
      <table className="table">
        <thead className="thead-dark">
        <tr>
          <th>ID</th>
          <th>Data</th>
          <th>Suma</th>
          <th>Status</th>
        </tr>
        </thead>
        <tbody>
        {orders.map(order => (
          <tr key={order.id}>
            <td><Link to={`/orders/${order.id}`}>{order.id}</Link></td>
            <td>{formatDate(order.createDate)}</td>
            <td>{formatPrice(order.totalPrice)}</td>
            <td>{formatStatus(order.status)}</td>
          </tr>
        ))}
        </tbody>
      </table>
    </div>
  );
};

const OrderList = () => {
  const context = useContext(DefaultBusinessContext);
  const { orderId } = useParams();
  const [orders, setOrders] = useState(null as unknown as DataModel.Order[]);
  const [error, setError] = useState(null as unknown as TechnicalErrorDetails);
  useEffect(() => {
    if (null == orders) {
      setError(null as unknown as TechnicalErrorDetails);
      context.getMyOrders()
        .then(setOrders)
        .catch(createTechnicalErrorHandler(setError))
    }
  }, [orders, context, orderId]);
  if (null != error) {
    return (
      <div className="container">
        <div className="mt-5">
          <TechnicalError error={error}/>
        </div>
      </div>
    )
  }
  if (null == orders) {
    return (
      <div className="container">
        <Loading/>
      </div>
    );
  }
  return (
    <div className="container order-details">
      <div className="row">
        <div className="col-12 col-md-8 offset-md-2">
          <h3 className="mt-5">Moje zamówienia</h3>
          <hr className="my-4"/>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-8 offset-md-2">
          <OrdersTable orders={orders}/>
        </div>
      </div>
    </div>
  );
}

export default OrderList;
