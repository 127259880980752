import React, { useContext } from 'react';
import { BrowserRouter as Router, Link, Route, Switch, useHistory } from 'react-router-dom';
import './App.scss';
import DefaultBusinessContext, { BusinessContext } from '../business/BusinessContext';
import CourseDetails from './CourseDetails';
import Home from './Home';
import Lesson from './Lesson';
import Login from './Login';
import ForgotPassword from './ForgotPassword';
import OrderDetails from './OrderDetails';
import Register from './Register';
import MyCourses from './MyCourses';
import OrderList from './OrderList';
import AccountSettings from './AccountSettings';
import Checkout from './Checkout';
import Contact from './Contact';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart, faUserCircle } from '@fortawesome/free-solid-svg-icons'
import { SEO, toggleBootstrapElement } from '../util';
import Landing from './Landing';
import Loading from './Loading';
import ResetPassword from './ResetPassword';
import { Helmet } from 'react-helmet';
import TermsOfUse from './TermsOfUse';


const toggleNavbar = toggleBootstrapElement.bind(null, "#navbarSupportedContent")
const toggleUserMenu = toggleBootstrapElement.bind(null, "#user-menu")

const CartMenuItem = () => {
  const context = useContext(DefaultBusinessContext);
  const cartItemsCount = context.getCartContents().length;
  if (!cartItemsCount) {
    return null;
  }
  return (
    <li className="nav-item">
      <Link className="nav-link" to="/checkout" onClick={toggleNavbar}>
        <FontAwesomeIcon icon={faShoppingCart}/>
        {cartItemsCount}
      </Link>
    </li>
  );
};

const AnonymousUserMenu = () => (
  <ul className="navbar-nav">
    <CartMenuItem/>
    <li className="nav-item ">
      <Link to="/login" className="nav-link" onClick={toggleNavbar}>Logowanie</Link>
    </li>
    <li className="nav-item ">
      <Link to="/register" className="nav-link" onClick={toggleNavbar}>Rejestracja</Link>
    </li>
  </ul>
);

const UserMenu = () => {
  const history = useHistory();
  const context = useContext(DefaultBusinessContext);
  if (!context.user) {
    return (<AnonymousUserMenu/>);
  }
  const logout = () => {
    context.logout();
    toggleNavbar();
    history.replace('/login')
  };
  return (
    <ul className="navbar-nav">
      <CartMenuItem/>
      <li className="nav-item dropdown" onClick={toggleUserMenu}>
        <button className="nav-link btn btn-link dropdown-toggle" id="navbarDropdown" type="button" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">
          <FontAwesomeIcon icon={faUserCircle}/>
        </button>
        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown" id="user-menu">
          <div className="dropdown-item-text disabled">{context.user.name}</div>
          <div className="dropdown-divider"/>
          <Link className="dropdown-item" to="/me/courses" onClick={toggleNavbar}>Moje kursy</Link>
          <Link className="dropdown-item" to="/me/orders" onClick={toggleNavbar}>Moje zamówienia</Link>
          <Link className="dropdown-item" to="/me" onClick={toggleNavbar}>Ustawienia konta</Link>
          <button className="dropdown-item" type="button" onClick={logout}>Wyloguj</button>
        </div>
      </li>
    </ul>
  );
};

const Routes = () => (
  <Switch>
    <Route exact path="/">
      <Home/>
    </Route>
    <Route exact path="/checkout">
      <Checkout/>
    </Route>
    <Route exact path="/contact">
      <Contact/>
    </Route>
    <Route exact path="/start">
      <Landing/>
    </Route>
    <Route exact path="/login">
      <Login/>
    </Route>
    <Route exact path="/forgot-password">
      <ForgotPassword/>
    </Route>
    <Route exact path="/register">
      <Register/>
    </Route>
    <Route exact path="/reset-password/:token">
      <ResetPassword/>
    </Route>
    <Route exact path="/course/:courseId">
      <CourseDetails/>
    </Route>
    <Route exact path="/course/:courseId/lesson/:lessonId">
      <Lesson/>
    </Route>
    <Route exact path="/me/courses">
      <MyCourses/>
    </Route>
    <Route exact path="/me/orders">
      <OrderList/>
    </Route>
    <Route exact path="/me">
      <AccountSettings/>
    </Route>
    <Route exact path="/terms-of-use">
      <TermsOfUse/>
    </Route>
    <Route exact path="/orders/:orderId">
      <OrderDetails/>
    </Route>
  </Switch>
);

type AppState = {
  businessContext: BusinessContext;
  initiated: boolean;
};

class App extends React.Component<any, AppState> {

  unregisterBusinessContextChangeListener?: () => void;

  constructor(props: any) {
    super(props);
    const businessContext = new BusinessContext(null);
    this.state = { businessContext, initiated: false };
    this.registerBusinessContextChange(businessContext);
  }

  async componentDidMount() {
    await this.state.businessContext.init();
    this.setState({ initiated: true });
  }

  componentWillUnmount() {
    this.unregisterBusinessContextChangeListenerIfNeeded();
  }

  render() {
    if (!this.state.initiated) {
      return (<Loading/>);
    }
    return (
      <DefaultBusinessContext.Provider value={this.state.businessContext}>
        <Router>
          <Helmet defaultTitle={SEO.siteName} titleTemplate={`%s - ${SEO.siteName}`}/>
          <div className="container">
            <nav className="navbar navbar-expand-lg navbar-light">
              <button className="navbar-toggler" type="button" data-toggle="collapse" aria-controls="navbarSupportedContent"
                      aria-expanded="false" aria-label="Toggle navigation" onClick={toggleNavbar}>
                <span className="navbar-toggler-icon"/>
              </button>
              <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav mr-auto">
                  <li className="nav-item">
                    <Link to="/" className="nav-link" onClick={toggleNavbar}>Kursy</Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/contact" className="nav-link" onClick={toggleNavbar}>Kontakt</Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/terms-of-use" className="nav-link" onClick={toggleNavbar}>Regulamin</Link>
                  </li>
                </ul>
                <UserMenu/>
              </div>
            </nav>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm nav-bar-shadow"/>
            </div>
          </div>
          <Routes/>
        </Router>
      </DefaultBusinessContext.Provider>
    );
  }

  private registerBusinessContextChange(ctx: BusinessContext) {
    this.unregisterBusinessContextChangeListener = ctx.onChange((businessContext: BusinessContext) => {
      this.unregisterBusinessContextChangeListenerIfNeeded();
      this.registerBusinessContextChange(businessContext);
      this.setState({ ...this.state, businessContext })
    });
  }

  private unregisterBusinessContextChangeListenerIfNeeded() {
    if (this.unregisterBusinessContextChangeListener) {
      this.unregisterBusinessContextChangeListener();
    }
  }
}

export default App;
