import React from 'react';
import './Loading.scss'
import { className } from '../../util';

type LoadingProps = {
  centerVertically?: boolean
};
const Loading = ({ centerVertically }: LoadingProps) => {
  return (
    <div className="loading-view">
      <div className={className({ 'center-vertically': false !== centerVertically })}>
        <strong className="d-block text-center mb-3">Ładuję...</strong>
        <div className="spinner-border text-info d-block mx-auto spinner" role="status">
          <span className="sr-only">Ładuję...</span>
        </div>
      </div>
    </div>
  )
}

export default Loading;
