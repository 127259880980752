import React, { useContext, useEffect, useState } from 'react';
import DefaultBusinessContext from '../../business/BusinessContext';
import { CourseList } from '../CourseList';
import { CourseListItem } from '../../business/DataModel';
import { useHistory } from 'react-router-dom';
import TechnicalError from '../TechnicalError';
import { createTechnicalErrorHandler, TechnicalErrorDetails } from '../../util';

const MyCourses = () => {
  const context = useContext(DefaultBusinessContext);
  const history = useHistory();
  const [myCourses, setMyCourses] = useState(null as unknown as CourseListItem[]);
  const [error, setError] = useState(null as unknown as TechnicalErrorDetails);
  useEffect(() => {
    if (!context.user) {
      history.replace('/login');
      return;
    }
    if (null == myCourses) {
      context.getMyCourses()
        .then(setMyCourses)
        .catch(createTechnicalErrorHandler(setError))
    }
  }, [context, history, myCourses]);
  if (null != error) {
    return (
      <div className="container">
        <div className="mt-5">
          <TechnicalError error={error}/>
        </div>
      </div>
    )
  }
  return (
    <div className="home-view my-courses-view">
      <div className="container">
        {context.user && <CourseList courses={myCourses} title="Moje kursy"/>}
      </div>
    </div>
  );
}

export default MyCourses;
