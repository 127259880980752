import React from 'react';

import { SEO } from '../../util';
import Background from '../../assets/images/header.jpg';
import { Helmet } from 'react-helmet';

const DefaultMetaTags = ({ children }: { children?: any }) => (
  <Helmet>
      <meta name="description" content={SEO.description}/>
      <meta name="keywords" content={SEO.keywords}/>
      <meta property="og:title" content={SEO.siteName}/>
      <meta property="og:description" content={SEO.description}/>
      <meta property="og:image" content={Background}/>
      <meta property="og:site_name" content={SEO.siteName}/>
      <meta property="og:type" content="website"/>
      <meta property="og:locale" content="pl_PL"/>
      <meta property="og:url" content="https://montessori.courses"/>
      {children}
  </Helmet>
);

export default DefaultMetaTags;
