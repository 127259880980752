import React, { useContext } from 'react';
import './Landing.scss';
import landing1 from '../../assets/images/landing1.jpg';
import landing2 from '../../assets/images/landing2.jpg';
import landing3 from '../../assets/images/landing3.jpg';
import landing4 from '../../assets/images/landing4.jpg';
import Video from '../Video';
import DefaultMetaTags from '../DefaultMetaTags';
import { Link } from 'react-router-dom';
import DefaultBusinessContext from '../../business/BusinessContext';

type SkewedCardProps = { src: string };
const SkewedCard = ({ src }: SkewedCardProps) => (
  <div className="skewed-card col-md-2 d-none d-md-block">
    <div className="card-container">
      <div className="card-component">
        <div className="front">
          <img src={src} className="card-img-top" alt="..."/>
        </div>
      </div>
    </div>
  </div>
)

const Landing = () => {
  const context = useContext(DefaultBusinessContext);
  const onResolutionChange = (resolution: string) => context.setVideoResolution(resolution);
  const resolution = context.getVideoResolution() || undefined;
  const trailerSources = [
    { resolution: 240, videoUrl: 'https://montessori-course.s3.eu-central-1.amazonaws.com/landing-trailer-240.mp4' },
    { resolution: 480, videoUrl: 'https://montessori-course.s3.eu-central-1.amazonaws.com/landing-trailer-480.mp4' },
    { resolution: 1080, videoUrl: 'https://montessori-course.s3.eu-central-1.amazonaws.com/landing-trailer-1080.mp4' }
  ];
  const preview = trailerSources.find(video => 1080 === video.resolution) || { videoUrl: '' };
  const canonicalUrl = 'https://montessori.courses/start';
  const ldJson = {
    "@context": "https://schema.org",
    "@type": "VideoObject",
    "contentURL": preview.videoUrl,
    "embedUrl": canonicalUrl,
    "description": 'Poznaj pedagogikę Montessori',
    "duration": 'PT1M23S',
    "name": 'Kursy Montessori Online',
    "thumbnailUrl": 'https://montessori-course.s3.eu-central-1.amazonaws.com/landing-trailer-thumbnail.jpg',
  };
  return (
    <div className="landing-view">
      <DefaultMetaTags>
        <script type="application/ld+json">{JSON.stringify(ldJson, null, 2)}</script>
      </DefaultMetaTags>
      <header>
        <div className="container text-center">
          <div className="row">
            <div className="col-12 pb-5 mb-md-0 font-sriracha">
              <h1 className="mt mb-5">Kursy Montessori Online</h1>
              <h5 className="pt-2">Poznaj pedagogikę Montessori</h5>
              <h5 className="pt-2">Tanio i Wygodnie</h5>
            </div>
          </div>
        </div>
      </header>
      <section>
        <div className="container py-5">
          <div className="row">
            <div className="col">
              <Video sources={trailerSources} onResolutionChange={onResolutionChange} resolution={resolution}/>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-5">
        <div className="container">
          <div className="row">
            <div className="col font-sriracha">
              <h3 className="text-center title-line mb-5">
                <span>Poznasz wspaniałą metodę nauczania</span>
              </h3>
              <h5>Według Metody Montessori głównym"aktorem" w procesie edukacji jest dziecko.
                Nauczymy cię jak towarzyszyć dziecku w tej drodze, jak spokojnie, bez bólu i stresu inspirować dzieci do
                poznawania siebie i otaczających je zjawisk,
                a przede wszystkim, jak podtrzymać i pielęgnować w dziecku jego naturalną ciekawość świata.</h5>
              <h3 className="text-center title-line my-5">
                <span>Realizujesz kurs z domu</span>
              </h3>
              <h5>Pracujesz w swoim tempie nie wychodząc z domu.
                Nie musisz jeździć na drugi koniec Polski ani podporządkowywać
                kalendarza całej rodziny pod kurs stacjonarny.</h5>
              <h3 className="text-center title-line my-5">
                <span>Materiały video</span>
              </h3>
              <h5>Jeden obraz wart jest tysiąca słów.
                Nauczyciele Montessori z długoletnim stażem przyznają, że z czasem zapominają jak pracować z materiałami,
                których nie wykorzystywali ostatnio a skrypt z tradycyjnego kursu nie zastąpi materiału video. </h5>
              <h3 className="text-center title-line my-5">
                <span>Jak uczyć dziecko w domu</span>
              </h3>
              <h5>Jeżeli chcesz pracować z dzieckiem w domu, ale nie wiesz jak się za to zabrać
                to myslę,
                że ten kurs jest odpowiedzią na Twoje potrzeby.</h5>
              <h3 className="text-center title-line my-5">
                <span>Szybka kalkulacja</span>
              </h3>
              <h5 className="card-text">Policzyłam, jaką kwotę wyłożyłam żeby poznać metodę.
                Mój stacjonarny kurs kosztował 7100 zł, wynajem mieszkania w Warszawie 4.000 zł.
                Dodatkowo koszty dojazdu 1000 zł. Co daje 12.100 zł. Pomijam czas i koszty praktyk.<br/><br/>
              </h5>
              <div className="text-center">
                <Link to="/" className="btn btn-outline-success btn-lg rounded-0 mt-5 mb-5">
                  Zobacz ile kosztują nasze kursy online!
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row ml-lg-5 ml-md-0">
            {[landing1, landing3, landing2, landing4].map(src => <SkewedCard src={src} key={src}/>)}
          </div>
        </div>
      </section>
    </div>
  )
};

export default Landing;
