import * as DataModel from '../../business/DataModel';
import { CourseListItem } from '../CourseListItem';
import React from 'react';
import { Link } from 'react-router-dom';
import Loading from '../Loading';

type CourseListProps = {
  courses?: DataModel.CourseListItem[],
  title: string,
  link?: string
}
export const CourseList = ({ courses, link, title }: CourseListProps) => {
  const titleHeader = <h3 className="section-title">{title}</h3>;
  return (
    <div>
      {(link && <Link to="/me/courses">{titleHeader}</Link>) || titleHeader}
      {!courses && <Loading centerVertically={false}/>}
      <div className="row mb-5">

        {courses && courses.map(course => (<CourseListItem course={course} key={course.id}/>))}
      </div>
    </div>
  );
}
