import React, { useContext, useEffect, useState } from 'react';
import Video from '../Video';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckSquare, faSquare } from '@fortawesome/free-solid-svg-icons'

import './Lesson.scss';
import { Link, useHistory, useParams } from 'react-router-dom';
import DefaultBusinessContext from '../../business/BusinessContext';
import * as DataModel from '../../business/DataModel';
import { VideoSource } from '../../business/DataModel';
import TechnicalError from '../TechnicalError';
import ExpectedError from '../ExpectedError';
import Loading from '../Loading';
import { extractTechnicalError, TechnicalErrorDetails } from '../../util';


type LessonListItemProps = {
  courseId: string,
  lessonId: string,
  lesson: DataModel.Lesson,
  active: boolean
}

const LessonListItem = ({ courseId, lesson, lessonId, active }: LessonListItemProps) => {
  return (
    <Link to={`/course/${courseId}/lesson/${lessonId}`} className={'list-group-item list-group-item-action ' + (active ? 'active' : '')}>
      <div>
        <FontAwesomeIcon icon={lesson.completed ? faCheckSquare : faSquare} className="mr-2"/>
        {lesson.title}</div>
      <div>
        <small>{lesson.duration} min</small>
      </div>
    </Link>
  );
}

const Lesson = () => {
  const { courseId, lessonId } = useParams();
  const context = useContext(DefaultBusinessContext);
  const history = useHistory();
  const [course, setCourse] = useState(null as unknown as DataModel.CourseDetails);
  const [error, setError] = useState(null as unknown as string);
  const [technicalError, setTechnicalError] = useState(null as unknown as TechnicalErrorDetails);
  const [lessonVideos, setLessonVideos] = useState(null as unknown as (VideoSource[] | null));

  useEffect(() => {
    const fetchVideoUrl = async () => {
      setLessonVideos(null);
      const videos = await context.getLessonVideoUrls(courseId, lessonId);
      setLessonVideos(videos);
    }

    if (null == course || course.id !== courseId) {
      setTechnicalError(null as unknown as TechnicalErrorDetails);
      setError(null as unknown as string);
      if (!context.user) {
        history.replace('/login');
        return;
      }
      context.getCourse(courseId)
        .then(courseDetails => {
          if ((!courseDetails.owned || courseDetails.redirectUrl) && !courseDetails.free) {
            history.replace(`/course/${courseId}`);
            return;
          }
          if (null == courseDetails.lessons[lessonId]) {
            const lessonNotFoundError = new Error('Lesson does not exist');
            lessonNotFoundError.name = 'LessonNotFound';
            throw lessonNotFoundError;
          }
          setCourse(courseDetails);
          if (courseDetails.currentLessonIndex !== lessonId) {
            courseDetails.currentLessonIndex = lessonId;
            context.setLessonAsCurrent(courseId, lessonId);
          }
          return fetchVideoUrl();
        }).catch(async (e: any) => {
        if ('LessonNotFound' === e.name) {
          setError(`Brak lekcji ${lessonId} w kursie ${courseId}`);
        } else if (404 === e.status) {
          setError(`Brak kursu ${courseId}`);
        } else {
          setTechnicalError(await extractTechnicalError(e));
        }
      });
    } else if (course.currentLessonIndex !== lessonId) {
      course.currentLessonIndex = lessonId;
      context.setLessonAsCurrent(courseId, lessonId);
      // noinspection JSIgnoredPromiseFromCall
      fetchVideoUrl();
    }
  }, [course, context, courseId, history, lessonId]);

  if (null != technicalError || null != error) {
    const errorComponent = null != error ? (<ExpectedError center={true} message={error}/>) : (<TechnicalError error={technicalError}/>);
    return (
      <div className="container">
        <div className="mt-5">
          {errorComponent}
        </div>
      </div>
    )
  }
  if (null == course) {
    return (
      <div className="container">
        <Loading/>
      </div>
    );
  }
  const onEnded = async () => {
    if (course.lessons[lessonId].completed) {
      return;
    }
    await context.markLessonCompleted(courseId, lessonId)
    course.lessons[lessonId].completed = true;
    setCourse({ ...course });
  };
  const onResolutionChange = (resolution: string) => context.setVideoResolution(resolution);
  const resolution = context.getVideoResolution() || undefined;
  return (
    <div className="container-fluid lesson-view">
      <div className="row">
        <div className="col-12 col-lg-8 p-0  mt-1">
          {!lessonVideos && <Loading/>}
          {lessonVideos &&
          <Video sources={lessonVideos} onEnded={onEnded} onResolutionChange={onResolutionChange} resolution={resolution}/>}
        </div>
        <div className=" col-lg-4 col-12 p-0 mt-1">
          <h5 className="p-3 bg-light shadow-sm">{course.title}:</h5>
          <ul className="list-group list-group-flush">
            {course.lessons.map((lesson, index) => (
              <LessonListItem courseId={courseId} lesson={lesson} lessonId={`${index}`} key={index}
                              active={index === parseInt(lessonId, 10)}/>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Lesson;
