import moment from 'moment';

export type TechnicalErrorDetails = {
  status?: string,
  body?: string,
  message?: string
};

export function className(...args: (string | Record<string, any>)[]): string {
  return args.reduce<string[]>((acc, i) => {
    if ("string" === typeof i) {
      acc.push(i as string);
    } else {
      Object.entries(i).forEach(([k, v]) => v && acc.push(k));
    }
    return acc;
  }, [] as string[])
    .join(' ');
}

export const extractTechnicalError = async (e: any): Promise<TechnicalErrorDetails> => {
  const result: TechnicalErrorDetails = {};
  result.status = e.status ? `${e.status} ${e.statusText}` : undefined;
  try {
    result.body = await e.text()
  } catch (ignore) {
  }
  result.message = e.message;
  return result;
};

export const createErrorHandler = (setError: (message: string) => void, setTechnicalError: (details: TechnicalErrorDetails) => void, mapping: { [status in number]: string }) => async (e: any) => {
  const message = mapping[e.status];
  if (message) {
    setError(message);
  } else {
    setTechnicalError(await extractTechnicalError(e));
  }
};

export const createTechnicalErrorHandler = (setError: (details: TechnicalErrorDetails) => void) => async (e: any) => {
  setError(await extractTechnicalError(e));
};

export const formatDate = (value: number) => {
  return moment(value).format('YYYY/MM/DD')
};

export const formatPrice = (value: number) => {
  const whole = Math.floor(value / 100);
  let cents = value - (whole * 100);
  let centsString = cents < 10 ? `0${cents}` : `${cents}`;
  return `${whole},${centsString} zł`;
};

export const formatStatus = (status: string) => {
  switch (status) {
    case 'created':
      return 'Złożone';
    case 'paid':
      return 'Zapłacone';
    default:
      return status
  }
}

export const hasValues = (value: any): boolean => 0 < Object.values(value).filter(i => i != null).length;

export const SEO = {
  description: 'Poznaj pedagogikę Montessori tanio i wygodnie, we własnym tempie, nie wychodząc z domu',
  keywords: 'Kursy Montessori Online, Kursy video',
  siteName: 'Kursy Montessori Online'
};

export const toggleBootstrapElement = (targetId: string) => {
  const querySelector = document.querySelector(targetId.valueOf());
  if (!querySelector) return;
  const classList = querySelector.classList;
  if (classList.contains('show')) {
    classList.remove('show');
  } else {
    classList.add('show');
  }
};
