import React, { useContext, useEffect, useState, useCallback } from 'react';

import './CourseDetails.scss'
import { className, createErrorHandler, formatPrice, SEO, TechnicalErrorDetails } from '../../util';
import Video from '../Video';
import DefaultBusinessContext from '../../business/BusinessContext';
import { Link, useHistory, useParams } from 'react-router-dom';
import * as DataModel from '../../business/DataModel';
// import { OrderLineItem } from '../../business/DataModel';
import TechnicalError from '../TechnicalError';
import Loading from '../Loading'
import { Helmet } from 'react-helmet';
import ExpectedError from '../ExpectedError';

const CourseDetails = () => {
  const context = useContext(DefaultBusinessContext);
  const { courseId } = useParams();
  const [course, setCourse] = useState(null as unknown as DataModel.CourseDetails);
  const [technicalError, setTechnicalError] = useState(null as unknown as TechnicalErrorDetails);
  const [error, setError] = useState(null as unknown as string);
  const history = useHistory();
  useEffect(() => {
    if (null == course || course.id !== courseId) {
      setTechnicalError(null as unknown as TechnicalErrorDetails);
      setError(null as unknown as string);
      context.getCourse(courseId)
        .then(setCourse)
        .catch(createErrorHandler(setError, setTechnicalError, { 404: `Brak kursu o identyfikatorze: ${courseId}` }));
    }
  }, [course, context, courseId]);
  const redirect = useCallback(() => {
    if (null != course?.redirectUrl)
      window.location.href=course.redirectUrl;
  }, [course]);
  if (null != technicalError || null != error) {
    const errorComponent = null != error ? (<ExpectedError center={true} message={error}/>) : (<TechnicalError error={technicalError}/>);
    return (
      <div className="container">
        <div className="mt-5">
          {errorComponent}
        </div>
      </div>
    )
  }
  if (null == course) {
    return (
      <div className="container">
        <Loading/>
      </div>
    );
  }
  // const buyNow = () => {
  //   const lineItem: OrderLineItem = {
  //     id: course.id,
  //     price: course.price,
  //     quantity: 1,
  //     title: course.title
  //   };
  //   context.addToCart(lineItem);
  //   history.push('/checkout');
  // };
  const signInToContinue = () => {
    history.replace('/login');
  };
  const description = course.description
    .split('\n')
    .filter(i => i)
    .reduce((acc, value, idx) => acc.concat(<li key={idx}>{value}</li>), [] as JSX.Element[]);
  const ctaButtonsClassName = "btn btn-primary w-100 rounded-0 mb-2";
  const signInToContinueButton = (<button className={ctaButtonsClassName} onClick={signInToContinue}>Zaloguj się by rozpocząć</button>);
  // const buyNowButton = (<button className={ctaButtonsClassName} onClick={buyNow}>Kup teraz</button>);
  const redirectButton = (<button className={ctaButtonsClassName} onClick={redirect}>Kurs dostępny na eduj.pj</button>);
  const continueCourseButton = (
    <Link className={ctaButtonsClassName} to={`/course/${courseId}/lesson/${course.currentLessonIndex}`}>Rozpocznij</Link>);

  const onResolutionChange = (resolution: string) => context.setVideoResolution(resolution);
  const resolution = context.getVideoResolution() || undefined;
  const canonicalUrl = `https://montessori.courses/course/${courseId}/`;
  const ldJson = {
    "@context": "https://schema.org",
    "@type": "Course",
    "name": course.title,
    "description": course.summary,
    "provider": {
      "@type": "Organization",
      "name": "Kursy Montessori Online",
      "sameAs": "https://montessori.courses"
    }
  };
  return (
    <div className="container course-details">
      <Helmet title={course.title}>
        <meta name="description" content={course.summary}/>
        <meta name="keywords" content={course.description.split('\n').filter(i => i).join(',')}/>
        <meta property="og:title" content={course.title}/>
        <meta property="og:description" content={course.summary}/>
        <meta property="og:image" content={course.thumbnail}/>
        <meta property="og:site_name" content={SEO.siteName}/>
        <meta property="og:type" content="video.other"/>
        <meta property="og:locale" content="pl_PL"/>
        <meta property="og:url" content={canonicalUrl}/>
        <link rel="canonical" href={canonicalUrl}/>
        <script type="application/ld+json">{JSON.stringify(ldJson, null, 2)}</script>
      </Helmet>
      <h1 className="mt-5">{course.title}</h1>
      <div className="xmt-5">{course.summary}</div>
      <hr/>

      <div className="row">
        <div className=" col-12 col-lg-8 order-2 order-lg-1">
          {!!(course.whatWillYouLearn && course.whatWillYouLearn.length) && (
            <div>
              <h2 className="mt-5">Czego się nauczysz</h2>
              <hr/>
              <ul className="spacious-list">
                {course.whatWillYouLearn.map((skill, i) => <li key={i}>{skill}</li>)}
              </ul>
            </div>
          )}
          {!!(course.description && course.description.trim().length) && (
            <div>
              <h2 className="mt-5">Wykorzystywane pomoce</h2>
              <hr/>
              <ul className="spacious-list">{description}</ul>
            </div>
          )}
        </div>
        <div className=" col-12 col-lg-4 order-1 order-lg-2">
          <div className="card shadow-sm">
            {!!(course.previewUrl && course.previewUrl.length) && (
              <Video sources={course.previewUrl} poster={course.thumbnail} onResolutionChange={onResolutionChange} resolution={resolution}/>
            )}
            {!(course.previewUrl && course.previewUrl.length) && course.thumbnail && (<img src={course.thumbnail} alt={course.title}/>)}

            <div className="card-body">
              {!course.owned && course.available && !course.redirectUrl &&
                <h3 className={className('card-title text-center mb-3', { 'free-course': course.free })}>{formatPrice(course.price)}</h3>}
              {course.free && <div className="text-center mb-3">Tymczasowo kurs dostępny bezpłatnie</div>}
              {course.available && (
                <div className="text-center">
                  {course.free && (context.user ? continueCourseButton : signInToContinueButton)}
                  {/*{!course.free && (course.owned ? continueCourseButton : buyNowButton)}*/}
                  {!course.free && (course.redirectUrl ? redirectButton : (course.owned ? continueCourseButton : null))}
                </div>
              )}
              {!course.available && <div className="alert alert-secondary text-center">W przygotowaniu</div>}
              <div>
                <b>Ten kurs obejmuje:</b>
                <ul>
                  <li>{course.videoLength} min. treści wideo na życzenie</li>
                  <li>{course.accessDuration} miesięcy dostępu</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CourseDetails;
