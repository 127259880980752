import React from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { className, extractTechnicalError, hasValues, TechnicalErrorDetails } from '../../util';
import ExpectedError from '../ExpectedError';
import TechnicalError from '../TechnicalError';
import DefaultBusinessContext from '../../business/BusinessContext';
import RequiredFieldError from '../RequiredFieldError';
import LaddaButton from 'react-ladda';

type RegisterProps = RouteComponentProps;

type ValidationErrors = {
  nameError?: string
  passwordConfirmationError?: string
};

type RegisterState = {
  email?: string,
  error?: string,
  mailingListConsent: boolean,
  validated: boolean,
  name?: string,
  password?: string,
  passwordConfirmation?: string,
  signupInProgress: boolean,
  technicalError?: TechnicalErrorDetails
} & ValidationErrors;

class Register extends React.Component<RegisterProps, RegisterState> {

  private nameRef = React.createRef<HTMLInputElement>();
  private passwordConfirmationRef = React.createRef<HTMLInputElement>();

  constructor(props: RegisterProps) {
    super(props);
    this.onEmailChange = this.onEmailChange.bind(this);
    this.onNameChange = this.onNameChange.bind(this);
    this.onPasswordChange = this.onPasswordChange.bind(this);
    this.onPasswordConfirmationChange = this.onPasswordConfirmationChange.bind(this);
    this.toggleMailingListConsent = this.toggleMailingListConsent.bind(this);
    this.register = this.register.bind(this);
    this.state = { mailingListConsent: false, signupInProgress: false, validated: false };
  }

  render() {
    const { error, mailingListConsent, nameError, passwordConfirmationError, signupInProgress, technicalError, validated } = this.state;
    return (
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6 offset-md-3 mt-5">
            <h3>Zarejestruj się</h3>
            <hr className="my-4"/>
            <form onSubmit={this.register} noValidate className={className({ 'was-validated': validated })}>
              <div className="form-group">
                <label htmlFor="name">Imię i nazwisko</label>
                <input ref={this.nameRef} type="text" className={className('form-control', { 'is-invalid': nameError })} id="name"
                       onChange={this.onNameChange} required/>
                <RequiredFieldError message={nameError}/>
              </div>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input type="email" className="form-control" id="email" onChange={this.onEmailChange} required/>
                <RequiredFieldError message={'Wymagany poprawny email'}/>
              </div>
              <div className="form-group">
                <label htmlFor="password">Hasło</label>
                <input type="password" className="form-control" id="password" onChange={this.onPasswordChange} minLength={6} required/>
                <RequiredFieldError message={'Hasło musi mieć conajmniej 6 znaków'}/>
              </div>
              <div className="form-group">
                <label htmlFor="passwordConfirmation">Potwierdź hasło</label>
                <input ref={this.passwordConfirmationRef} type="password"
                       className={className('form-control', { 'is-invalid': passwordConfirmationError })} id="passwordConfirmation"
                       onChange={this.onPasswordConfirmationChange} required/>
                <RequiredFieldError message={passwordConfirmationError}/>
              </div>
              <div className="form-check">
                <input type="checkbox" id="mailingListConsent" className="form-check-input" checked={mailingListConsent}
                       onChange={this.toggleMailingListConsent}/>
                <label className="form-check-label pl-3 mb-3" htmlFor="mailingListConsent">Chcę otrzymywać informacje o promocjach i
                  nowościach</label>
              </div>

              {error && <ExpectedError message={error}/>}
              {technicalError && <TechnicalError error={technicalError}/>}

              <LaddaButton loading={signupInProgress} className="btn btn-primary rounded-0 w-100 mb-5">Zarejestruj się</LaddaButton>
            </form>
            <div className="text-center">Masz już konto? <Link to="/login">Zaloguj się</Link></div>
          </div>
        </div>
      </div>
    );
  }

  onEmailChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ ...this.state, email: event.currentTarget.value });
  }

  onNameChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ ...this.state, name: event.currentTarget.value },
      () => {
        const { nameError: prev } = this.state;
        const { nameError } = this.validate();
        if (prev !== nameError) {
          this.setState({ nameError });
        }
        this.updateNameCustomValidity({ nameError });
      });
  }

  onPasswordChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ ...this.state, password: event.currentTarget.value });
  }

  onPasswordConfirmationChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ ...this.state, passwordConfirmation: event.currentTarget.value, passwordConfirmationError: undefined },
      () => {
        const { passwordConfirmationError: prev } = this.state;
        const { passwordConfirmationError } = this.validate();
        if (prev !== passwordConfirmationError) {
          this.setState({ passwordConfirmationError });
        }
        this.updatePasswordConfirmationCustomValidity({ passwordConfirmationError });
      });
  }

  async register(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const { email, mailingListConsent, name, password, passwordConfirmation } = this.state;
    this.setState({ validated: true, error: undefined, technicalError: undefined });
    const newState = this.validate();
    if (hasValues(newState)) {
      this.setState(newState);
      return;
    }
    if (!email || !password || !name || !passwordConfirmation || !event.currentTarget.checkValidity()) {
      return;
    }
    this.setState({ signupInProgress: true });
    try {
      await this.context.registerUser({ email, mailingListConsent, name, password });
      const redirectTo = this.context.isCartEmpty() ? '/' : '/checkout';
      this.props.history.replace(redirectTo);
    } catch (e) {
      if (421 === e.status) {
        this.setState({ error: 'Użytkownik z tym emailem jest już zarejestrowany.' });
      } else {
        this.setState({ technicalError: await extractTechnicalError(e) });
      }
    } finally {
      this.setState({ signupInProgress: false });
    }
  }

  private toggleMailingListConsent() {
    this.setState(({ mailingListConsent }) => ({ mailingListConsent: !mailingListConsent }));
  }

  private validate(): ValidationErrors {
    const newState: ValidationErrors = {};
    const { name, password, passwordConfirmation } = this.state;
    if (password !== passwordConfirmation) {
      newState.passwordConfirmationError = 'Hasła się nie zgadzają';
    }
    if (!name || !name.trim().length) {
      newState.nameError = 'Pole jest wymagane';
    }
    this.updatePasswordConfirmationCustomValidity(newState);
    return newState;
  }

  private updatePasswordConfirmationCustomValidity(newState: ValidationErrors) {
    if (this.passwordConfirmationRef.current) {
      this.passwordConfirmationRef.current.setCustomValidity(newState.passwordConfirmationError || '');
    }
  }

  private updateNameCustomValidity(newState: ValidationErrors) {
    if (this.nameRef.current) {
      this.nameRef.current.setCustomValidity(newState.nameError || '');
    }
  }
}

Register.contextType = DefaultBusinessContext;

export default withRouter(Register);
