import React, { useContext, useEffect, useState } from 'react';
import DefaultBusinessContext from '../../business/BusinessContext';
import { className, extractTechnicalError, TechnicalErrorDetails } from '../../util';
import TechnicalError from '../TechnicalError';
import RequiredFieldError from '../RequiredFieldError';
import ExpectedError from '../ExpectedError';
import LaddaButton from 'react-ladda';

const RECAPTCHA_KEY = process.env.REACT_APP_RECAPTCHA_KEY || '6LfQeOAZAAAAACXhQC3LoCWPO9KpJHzEmXGMsSOH';

const loadScriptByURL = (id: string, url: string) => {
  const isScriptExist = document.getElementById(id);

  if (!isScriptExist) {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = url;
    script.id = id;
    document.body.appendChild(script);
  }
}

type ResetPasswordFormProps = {
  setTechnicalError: (e: TechnicalErrorDetails) => void
};
const ContactForm = ({ setTechnicalError }: ResetPasswordFormProps) => {
  const context = useContext(DefaultBusinessContext);
  const [success, setSuccess] = useState(false);
  const [validated, setValidated] = useState(false);
  const [error, setError] = useState('');
  const [text, setText] = useState('');
  const [from, setFrom] = useState((context.user && context.user.email) || '');
  const [sendMessageInProgress, setSendMessageInProgress] = useState(false);

  useEffect(() => {
    loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_KEY}`);
  }, []);

  const sendMessage = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setSuccess(false);
    setError('');
    setValidated(true);
    setTechnicalError(null as unknown as TechnicalErrorDetails);

    if (!event.currentTarget.checkValidity()) {
      return;
    }
    setSendMessageInProgress(true);
    // @ts-ignore
    window.grecaptcha.ready(() => {
      // @ts-ignore
      window.grecaptcha.execute(RECAPTCHA_KEY, { action: 'submit' }).then(async token => {
        try {
          await context.submitContactForm(token, from, text);
          setText('');
          setFrom('');
          setValidated(false);
          setSuccess(true);
        } catch (e) {
          if (401 === e.status) {
            setError('Google twierdzi, że jesteś robotem');
          } else {
            setTechnicalError(await extractTechnicalError(e));
          }
        } finally {
          setSendMessageInProgress(false);
        }
      });
    });

  };
  return (
    <form onSubmit={sendMessage} noValidate className={className({ 'was-validated': validated })}>
      {!context.user && <div className="form-group">
        <label htmlFor="from">Twój email</label>
        <input type="email" className="form-control" id="from" required value={from} onChange={e => setFrom(e.target.value)}/>
        <RequiredFieldError message={'Wymagany poprawny email'}/>
      </div>}
      <div className="form-group">
        <label htmlFor="text">Treść wiadomości</label>
        <textarea className="form-control" id="text" required value={text} onChange={e => setText(e.target.value)}/>
        <RequiredFieldError/>
      </div>
      {error && <ExpectedError message={error}/>}
      {success && (<div className="alert alert-success text-center py-1" role="alert">Wiadomość wysłana</div>)}
      <LaddaButton loading={sendMessageInProgress} className="btn btn-primary rounded-0 w-100 my-3">Wyślij</LaddaButton>
    </form>
  );
};

const Contact = () => {
  const [technicalError, setTechnicalError] = useState(null as unknown as TechnicalErrorDetails);
  return (
    <div className="container reset-password">
      <div className="row">
        <div className="col-12 col-md-6 offset-md-3 mt-5">
          <h3>Formularz kontaktowy</h3>
          <hr className="my-4"/>
          {technicalError && <div className="col-12"><TechnicalError error={technicalError}/></div>}
          <ContactForm setTechnicalError={setTechnicalError}/>
        </div>
      </div>
    </div>
  );
}

export default Contact;
