import React, { useContext, useEffect, useRef, useState } from 'react';
import DefaultBusinessContext from '../../business/BusinessContext';
import { User } from '../../business/DataModel';
import { useHistory } from 'react-router-dom';
import RequiredFieldError from '../RequiredFieldError';
import { className, extractTechnicalError, TechnicalErrorDetails } from '../../util';
import TechnicalError from '../TechnicalError';
import ExpectedError from '../ExpectedError';
import LaddaButton from 'react-ladda';


type SubFormProps = { setTechnicalError: (e: TechnicalErrorDetails) => void };
type ContactDetailsFormProps = SubFormProps;

const ContactDetailsForm = ({ setTechnicalError }: ContactDetailsFormProps) => {
  const context = useContext(DefaultBusinessContext);
  const history = useHistory();
  const [user, setUser] = useState(null as unknown as User);
  const [validated, setValidated] = useState(false);
  const [nameError, setNameError] = useState('');
  const [success, setSuccess] = useState(false);
  const [saveContactDataInProgress, setSaveContactDataInProgress] = useState(false);
  useEffect(() => {
    if (null == user) {
      if (!context.user) {
        history.replace('/login');
        return;
      }
      setUser(context.user);
    }
  }, [context, history, user]);
  const saveContactData = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setValidated(true);
    setNameError('');
    setTechnicalError(null as unknown as TechnicalErrorDetails);
    setSuccess(false);
    let hasErrors = false;
    if (!user.name || !user.name.trim().length) {
      setNameError('Pole jest wymagane');
      hasErrors = true;
    }
    if (hasErrors || !event.currentTarget.checkValidity()) {
      return;
    }
    setSaveContactDataInProgress(true);
    try {
      await context.updateUser(user);
      setSuccess(true);
      setValidated(false);
    } catch (e) {
      setTechnicalError(await extractTechnicalError(e));
    } finally {
      setSaveContactDataInProgress(false);
    }
  };
  if (!user) return null;
  return (
    <div>
      <h3>Dane kontaktowe</h3>
      <hr className="my-4"/>
      <form onSubmit={saveContactData} noValidate className={className({ 'was-validated': validated })}>
        <div className="form-group">
          <label htmlFor="name">Imię i nazwisko</label>
          <input type="text" className="form-control" id="name" value={user.name} onChange={e => setUser({ ...user, name: e.target.value })}
                 required/>
          <RequiredFieldError message={nameError}/>
        </div>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input type="email" className="form-control" id="email" value={user.email} disabled/>
        </div>
        <div className="form-check">
          <input type="checkbox" id="mailingListConsent" className="form-check-input" checked={user.mailingListConsent}
                 onChange={() => setUser({ ...user, mailingListConsent: !user.mailingListConsent })}/>
          <label className="form-check-label pl-3" htmlFor="mailingListConsent">
            Chcę otrzymywać informacje o promocjach i nowościach
          </label>
        </div>
        {success && (<div className="alert alert-success text-center py-1" role="alert">Dane kontaktowe zapisane</div>)}
        <LaddaButton loading={saveContactDataInProgress} className="btn btn-primary rounded-0 w-100 my-3">Zapisz</LaddaButton>
      </form>
    </div>
  );
}
type ChangePasswordFormProps = SubFormProps;

const ChangePasswordForm = ({ setTechnicalError }: ChangePasswordFormProps) => {
  const context = useContext(DefaultBusinessContext);
  const history = useHistory();
  const [user, setUser] = useState(null as unknown as User);
  const [validated, setValidated] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [currentPasswordError, setCurrentPasswordError] = useState('');
  const [passwordConfirmationError, setPasswordConfirmationError] = useState('');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');
  const [changePasswordInProgress, setChangePasswordInProgress] = useState(false);
  const passwordConfirmationRef = useRef(null);
  useEffect(() => {
    if (null == user) {
      if (!context.user) {
        history.replace('/login');
        return;
      }
      setUser(context.user);
    }
  }, [context, history, user]);
  const changePassword = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setValidated(true);
    setCurrentPasswordError('');
    setPasswordConfirmationError('');
    setError('');
    setTechnicalError(null as unknown as TechnicalErrorDetails);
    setSuccess(false);
    let hasErrors = false;
    if (newPassword !== passwordConfirmation) {
      const error = 'Hasła się nie zgadzają';
      setPasswordConfirmationError(error);
      // @ts-ignore
      passwordConfirmationRef.current.setCustomValidity(error);
      hasErrors = hasErrors || true;
    } else {
      // @ts-ignore
      passwordConfirmationRef.current.setCustomValidity('');
    }
    if (hasErrors || !event.currentTarget.checkValidity()) {
      return;
    }
    setChangePasswordInProgress(true);
    try {
      await context.changePassword(currentPassword, newPassword);
      setCurrentPassword('');
      setNewPassword('');
      setPasswordConfirmation('');
      setValidated(false);
      setSuccess(true);
    } catch (e) {
      if (403 === e.status) {
        setError('Niepoprawne hasło');
      } else {
        setTechnicalError(await extractTechnicalError(e));
      }
    } finally {
      setChangePasswordInProgress(false);
    }
  };
  if (!user) return null;
  return (
    <div>
      <h3>Zmień hasło</h3>
      <hr className="my-4"/>
      <form onSubmit={changePassword} noValidate className={className({ 'was-validated': validated })}>
        <div className="form-group">
          <label htmlFor="currentPassword">Obecne hasło</label>
          <input type="password" className="form-control" id="currentPassword" value={currentPassword}
                 onChange={e => setCurrentPassword(e.target.value)} required/>
          <RequiredFieldError message={currentPasswordError}/>
        </div>
        <div className="form-group">
          <label htmlFor="newPassword">Nowe hasło</label>
          <input type="password" className="form-control" id="newPassword" minLength={6} required value={newPassword}
                 onChange={e => setNewPassword(e.target.value)}/>
          <RequiredFieldError message={'Hasło musi mieć conajmniej 6 znaków'}/>
        </div>
        <div className="form-group">
          <label htmlFor="repeatNewPassword">Powtórz nowe hasło</label>
          <input ref={passwordConfirmationRef} type="password"
                 className={className('form-control', { 'is-invalid': passwordConfirmationError })} id="repeatNewPassword" required
                 value={passwordConfirmation} onChange={e => setPasswordConfirmation(e.target.value)}/>
          <RequiredFieldError message={passwordConfirmationError}/>
        </div>
        {error && (<ExpectedError message={error}/>)}
        {success && (<div className="alert alert-success text-center py-1" role="alert">Hasło zmienione</div>)}
        <LaddaButton loading={changePasswordInProgress} className="btn btn-primary rounded-0 w-100 my-3">Zmień hasło</LaddaButton>
      </form>
    </div>
  );
}

const AccountSettings = () => {
  const [technicalError, setTechnicalError] = useState(null as unknown as TechnicalErrorDetails);
  return (
    <div className="container change-password">
      <div className="row">
        {technicalError && <div className="col-12"><TechnicalError error={technicalError}/></div>}
        <div className="col-12 col-md-10 offset-md-1 col-lg-5 offset-lg-0 mt-5">
          <ContactDetailsForm setTechnicalError={setTechnicalError}/>
        </div>
        <div className="col-12 col-md-10 offset-md-1 col-lg-5 offset-lg-2 mt-5">
          <ChangePasswordForm setTechnicalError={setTechnicalError}/>
        </div>
      </div>
    </div>
  );
}

export default AccountSettings;
