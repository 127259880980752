import React from 'react';
import DefaultMetaTags from '../DefaultMetaTags';

const TermsOfUse = () => (
  <div className="terms-of-use-view">
    <DefaultMetaTags/>
    <div className="container">
      <div className="row">
        <div className="col">
          <h1>Regulamin</h1>
          <h2>I. Informacje ogólne</h2>
          <ol>
            <li>Niniejszy regulamin określa zasady korzystania ze strony internetowej https://montessori.courses (dalej:
              "montessori.courses").
            </li>
            <li>Korzystanie ze strony "montessori.courses" oznacza akceptację niniejszego regulaminu i polityki prywatności.</li>
            <li>Właścicielem strony montessori.courses jest W rozumieniu Regulaminu przez Usługę należy rozumieć:firma ITC Bernard Łabno,
              ul. Szczęśliwa 15, 33-100 Tarnów, dalej zwaną usługodawcą.
            </li>
            <li>Usługodawca umożliwia użytkownikowi ograniczony czasowo dostęp do materiałów video po dokonaniu płatności zgodnie z
              cennikiem znajdującym się na stronie.
            </li>
            <li>Materiały pogrupowane są w kursy. Każdy kurs posiada własną cenę oraz okres ważności, po którym dostęp do kursu wygasa.</li>
            <li>Materiały video będące częścią kursu są utworami w rozumieniu ustawy z dnia 4 lutego 1994 r.
              o prawie autorskim i prawach pokrewnych (tekst jedn. Dz. U. z 2006 r. Nr 90, poz. 631 ze zm.),
              na korzystanie z których licencji udziela Usługodawca.
            </li>
            <li>Dostęp do kursów możliwy jest po zarejestrowaniu konta użytkownika co jest równoznaczne z zaakceptowaniem regulaminu.</li>
            <li>Materiały video przeznaczone są do oglądania w trybie on-line i zabronione jest zapisywanie ich na dysku użytkownika, lub
              jakiekolwiek inne próby kopiowania obrazu lub dźwięku.
            </li>
          </ol>
          <h2>II. Wymagania techniczne</h2>
          <ol>
            <li>Do korzystania z serwisu niezbędna jest przeglądarka stron WWW zgodna z Google Chrome (najnowsza stabilna wersja)
              oraz dostęp do sieci Internet.
            </li>
          </ol>
          <h2>III. Konto użytkownika</h2>
          <ol>
            <li>Konto użytkownika identyfikowane jest przez adres email oraz hasło.</li>
            <li>Usługodawca nie zna hasła użytkownika i nie ponosi odpowiedzialności za utratę hasła przez użytkownika.</li>
            <li>Udostępnianie konta użytkownika innym osobom jest niedozwolone. Konto jest niezbywalne.</li>
            <li>Naruszenie zasad niniejszego regulaminu może skutokować zablokowaniem lub usunięciem konta użytkownika.</li>
            <li>Usiłowania naruczenia zabezpieczeń technicznych usługi lub użycia usługi niezgodnie z przeznaczeniem mogą skutkować
              usunięciem lub zablokowaniem konta użytkownika.
            </li>
            <li>W celu usunięcia konta należy skontaktować się z usługodawcą poprzez formularz kontaktowy.</li>
          </ol>
          <h2>IV. Zawarcie umowy o świadczenie usługi</h2>
          <ol>
            <li>Zawarcie umowy o świadczenie usługi następuje po potwierdzeniu przez operatora płatności online dokonania przez użytkownika
              opłaty za kurs.
            </li>
            <li>Dokonując zakupu użytkownik zgłasza żądanie rozpoczęcia świadczenia usługi przed upływem terminu na odstąpienie przez
              użytkownika
              od umowy.
            </li>
            <li>Ceny kursów zawierają podatek VAT.</li>
          </ol>
          <h2>V. Odstąpienie od umowy</h2>
          <ol>
            <li>Zgodnie z art. 27 Ustawy z dnia 30 maja 2014 r. o prawach konsumenta, Użytkownikowi będącemu Konsumentem w terminie 14 dni
              od zawarcia Umowy przysługuje prawo do odstąpienia od tej umowy bez podania przyczyny.
            </li>
            <li>Konsumentowi, nie przysługuje prawo do odstąpienia od umowy o dostarczanie treści cyfrowych, które nie są zapisane na
              nośniku materialnym,
              jeżeli spełnianie świadczenia rozpoczęło się za wyraźną zgodą Konsumenta przed upływem terminu do odstąpienia od umowy
              i po poinformowaniu go przez usługodawcę o utracie prawa odstąpienia od umowy.
            </li>
          </ol>
          <h2>VI. Reklamacje</h2>
          <ol>
            <li>Reklamacje należy zgłaszać za pośrednictwem formularza kontaktowego dostępnego na stronie "montessori.courses".</li>
            <li>Reklamacje rozpatrywane są w terminie 14 dni.</li>
            <li>Użytkownik zostanie poinformowany o decyzji w sprawie reklamacji na adres email podany przy rejestracji konta użytkownika.
            </li>
          </ol>
          <h2>VII. Polityka prywatności i ochrona danych osobowych</h2>
          <ol>
            <li>Administratorem danych osobowych przekazywanych przez Użytkowników jest Usługodawca.</li>
            <li>Usługodawca zobowiązuje się do ochrony danych osobowych zgodnie z Ustawą o ochronie danych osobowyc.</li>
            <li>Użytkownik podając przy składaniu zamówienia swoje dane osobowe Usługodawcy wyraża zgodę na ich przetwarzanie przez
              Usługodawcę w celu realizacji złożonego zamówienia.
            </li>
            <li>Użytkownik ma w każdej chwili możliwość wglądu, poprawiania, aktualizacji oraz usuwania swoich danych osobowych.</li>
          </ol>
          <h2>VIII. Postanowienia końcowe</h2>
          <ol>
            <li>Regulamin dostępny jest na stronie "montessori.courses"</li>
            <li>Zmiany w regulaminie będą widoczne na stronie z 14-dniowym wyprzedeniem.</li>
            <li>Użytkownik ma prawo poinformować usługodawcę o braku akceptacji zniam w regulaminie, w terminie 7 dni od publikacji
              regulaminu,
              co jest tożsame z rozwiązaniem umowy.
            </li>
            <li>Regulamin został wydany na podstawie ustawy z dnia 18 lipca 2002 r. o świadczeniu
              usług drogą elektroniczną (t.j. Dz. U. z 2019 r. poz. 123 z późn. zm.) oraz Ustawy o
              Prawach Konsumenta.
            </li>
            <li>W sprawach nieuregulowanych niniejszym Regulaminem zastosowanie będą miały
              właściwe przepisy prawa polskiego, w szczególności przepisy Kodeksu cywilnego.
            </li>
          </ol>
        </div>
      </div>
    </div>
  </div>
);

export default TermsOfUse;
