import React from 'react';
import { CourseDetails, CourseListItem, Order, OrderLineItem, User, UserRegistration, VideoSource } from './DataModel';
import Api from './Api';

type BusinessContextChangeListener = (ctx: BusinessContext) => void;

const LOCAL_STORAGE_KEY_AUTH_TOKEN = 'authToken';
const LOCAL_STORAGE_KEY_CART = 'cart';
const LOCAL_STORAGE_KEY_VIDEO_RESOLUTION = 'videoResolution';

export class BusinessContext {
  user?: User;
  private api: Api;
  private listeners: BusinessContextChangeListener[];
  private cart: OrderLineItem[];

  constructor(ctx: BusinessContext | null) {
    this.listeners = [];
    if (ctx) {
      this.cart = ctx.cart;
      this.user = ctx.user
      this.api = ctx.api;
    } else {
      this.api = new Api();
      this.cart = [];
    }
  }

  async init() {
    let changed = this.initCart();
    try {
      const authToken = localStorage.getItem(LOCAL_STORAGE_KEY_AUTH_TOKEN);
      if (null != authToken) {
        this.api.setAuthToken(authToken || undefined);
        this.user = await this.api.getMyUserProfile();
        changed = true;
      }
    } catch (e) {
      this.api.setAuthToken(undefined);
      localStorage.removeItem(LOCAL_STORAGE_KEY_AUTH_TOKEN);
    }
    if (changed)
      this.triggerChangeEvent(new BusinessContext(this));
  }

  addToCart(course: OrderLineItem) {
    //TODO instead of storing CourseDetails we should store as minimal thing as possible
    if (!this.cart.find(existingCourse => existingCourse.id === course.id)) {
      this.cart.push(course);
      this.triggerChangeEvent(new BusinessContext(this));
      localStorage.setItem(LOCAL_STORAGE_KEY_CART, JSON.stringify(this.cart));
    }
  }

  async changePassword(currentPassword: string, newPassword: string) {
    await this.api.changePassword(currentPassword, newPassword);
    if (null != this.api.authToken) {
      localStorage.setItem(LOCAL_STORAGE_KEY_AUTH_TOKEN, this.api.authToken);
    }
  }

  async initPasswordReset(email: string) {
    await this.api.initPasswordReset(email);
  }

  async resetPassword(token: string, password: string) {
    await this.api.resetPassword(token, password);
    if (null != this.api.authToken) {
      localStorage.setItem(LOCAL_STORAGE_KEY_AUTH_TOKEN, this.api.authToken);
    }
    this.user = await this.api.getMyUserProfile();
    this.triggerChangeEvent(new BusinessContext(this));
  }

  removeFromCart(course: OrderLineItem) {
    const index = this.cart.findIndex(existingCourse => existingCourse.id === course.id);
    if (index > -1) {
      this.cart.splice(index, 1);
      this.triggerChangeEvent(new BusinessContext(this));
      localStorage.setItem(LOCAL_STORAGE_KEY_CART, JSON.stringify(this.cart));
    }
  }

  clearCart() {
    this.cart = [];
    localStorage.removeItem(LOCAL_STORAGE_KEY_CART);
    this.triggerChangeEvent(new BusinessContext(this));
  }

  getCartContents(): OrderLineItem[] {
    return [...this.cart];
  }

  isCartEmpty() {
    return 0 === this.cart.length;
  }

  async payOrder(order: Order): Promise<void> {
    const paymentToken = await this.api.registerTransaction(order);
    window.location.href = `${process.env.REACT_APP_P24_URL}/trnRequest/${paymentToken}`;
  }

  async submitOrder(): Promise<Order> {
    const order = await this.api.submitOrder(this.cart);
    this.clearCart();
    this.triggerChangeEvent(new BusinessContext(this));
    return order
  }

  async login(email: string, password: string): Promise<void> {
    await this.api.authenticate(email, password);
    if (null != this.api.authToken) {
      localStorage.setItem(LOCAL_STORAGE_KEY_AUTH_TOKEN, this.api.authToken);
    }
    this.user = await this.api.getMyUserProfile();
    this.triggerChangeEvent(new BusinessContext(this));
  }

  async logout(): Promise<void> {
    try {
      await this.api.signOut();
    } catch (ignore) {
    } finally {
      localStorage.removeItem(LOCAL_STORAGE_KEY_AUTH_TOKEN);
    }
    this.user = undefined;
    this.triggerChangeEvent(new BusinessContext(this));
  }

  onChange(listener: BusinessContextChangeListener): () => void {
    this.listeners.push(listener);
    return () => (this.listeners = this.listeners.filter(i => i !== listener));
  }

  async getAllCourses(): Promise<CourseListItem[]> {
    const response = await this.api.searchCourses({ size: 100 });
    return response.results;
  }

  getCourse(courseId: string): Promise<CourseDetails> {
    return this.api.getCourseById(courseId)
  }

  getLessonVideoUrls(courseId: string, lessonId: number): Promise<VideoSource[]> {
    return this.api.getLessonVideoUrls(courseId, lessonId);
  }

  async getMyCourses(): Promise<CourseListItem[]> {
    const response = await this.api.searchMyCourses();
    return response.results.map(course => Object.assign(course, { owned: true }));
  }

  async getMyOrders(): Promise<Order[]> {
    const response = await this.api.searchOrders();
    return response.results;
  }

  getVideoResolution(): string | null {
    return localStorage.getItem(LOCAL_STORAGE_KEY_VIDEO_RESOLUTION);
  }

  getOrder(orderId: string): Promise<Order> {
    return this.api.getOrderById(orderId)
  }

  async markLessonCompleted(courseId: string, lessonId: number): Promise<void> {
    await this.api.markLessonCompleted(courseId, lessonId);
  }

  async registerUser(data: UserRegistration): Promise<void> {
    await this.api.registerUser(data);
    await this.login(data.email, data.password);
  }

  async setLessonAsCurrent(courseId: string, lessonId: number): Promise<void> {
    await this.api.setLessonAsCurrent(courseId, lessonId);
  }

  setVideoResolution(resolution: string): void {
    localStorage.setItem(LOCAL_STORAGE_KEY_VIDEO_RESOLUTION, resolution);
  }

  async submitContactForm(token: string, from: string, text: string): Promise<void> {
    await this.api.submitContactForm(token, from, text);
  }

  async updateMailingListConsent(consent: boolean): Promise<void> {
    if (this.user) {
      this.user.mailingListConsent = consent;
      await this.api.updateUser(this.user);
      this.triggerChangeEvent(new BusinessContext(this));
    }
  }

  async updateUser(user: User): Promise<void> {
    await this.api.updateUser(user);
    this.user = user;
    this.triggerChangeEvent(new BusinessContext(this));
  }

  private initCart(): boolean {
    try {
      const cartAsString = localStorage.getItem(LOCAL_STORAGE_KEY_CART);
      if (null != cartAsString) {
        this.cart = JSON.parse(cartAsString);
        return true;
      }
    } catch (ignore) {
    }
    return false;
  }

  private triggerChangeEvent(ctx: BusinessContext) {
    this.listeners.forEach(callback => callback(ctx));
  }
}

const DefaultBusinessContext = React.createContext(new BusinessContext(null));
export default DefaultBusinessContext;
