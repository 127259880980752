import React from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { className, extractTechnicalError, formatPrice, TechnicalErrorDetails } from '../../util';
import TechnicalError from '../TechnicalError';
import DefaultBusinessContext from '../../business/BusinessContext';
import { CourseDetails } from '../../business/DataModel';
import PaymentProviderLogo from '../../assets/images/Przelewy24_logo.svg';
import RequiredFieldError from '../RequiredFieldError';
import ExpectedError from '../ExpectedError';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import LaddaButton from 'react-ladda';

type CheckoutProps = RouteComponentProps;

type CheckoutState = {
  alreadyOwnedCourseId?: string,
  askForMailingListConsent: boolean,
  mailingListConsent: boolean,
  notAvailableCourseId?: string,
  submitOrderInProgress: boolean,
  termsOfUseConsent: boolean,
  validated: boolean,
  wantToPay: boolean,
  error?: string,
  technicalError?: TechnicalErrorDetails
};

class Checkout extends React.Component<CheckoutProps, CheckoutState> {

  constructor(props: CheckoutProps) {
    super(props);
    this.state = {
      mailingListConsent: false,
      askForMailingListConsent: false,
      submitOrderInProgress: false,
      termsOfUseConsent: false,
      validated: false,
      wantToPay: false
    };
    this.clearCart = this.clearCart.bind(this);
    this.removeFromCart = this.removeFromCart.bind(this);
    this.toggleMailingListConsent = this.toggleMailingListConsent.bind(this);
    this.toggleTermsOfUseConsent = this.toggleTermsOfUseConsent.bind(this);
    this.submitOrder = this.submitOrder.bind(this);
  }

  componentDidMount() {
    if (this.context.user && !this.context.user.mailingListConsent) {
      this.setState({ askForMailingListConsent: true });
    }
  }

  render() {
    const {
      alreadyOwnedCourseId, askForMailingListConsent, error, mailingListConsent, notAvailableCourseId, submitOrderInProgress,
      technicalError, termsOfUseConsent, validated, wantToPay
    } = this.state;

    const lineItems = (this.context.getCartContents() as CourseDetails[]).sort(({ order: a }, { order: b }) => {
      if (a === b) return 0;
      else if (a > b) return 1;
      else return -1;
    });
    if (!lineItems.length) {
      return (
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6 offset-md-3 mt-5">
              <h3>Koszyk jest pusty</h3>
              <Link className="btn btn-outline-info d-block rounded-0 mt-5" to="/">Zapraszamy na zakupy</Link>
            </div>
          </div>
        </div>
      );
    }
    const totalPrice = lineItems.reduce((acc, { price }) => acc + price, 0);
    const submitButton = (
      <LaddaButton loading={submitOrderInProgress} className="btn btn-primary rounded-0 my-4 btn-lg w-100">Zamawiam i płacę</LaddaButton>);
    return (
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-8 offset-md-2 mt-5">
            <div className="row ">
              <div className="col-12 d-flex">
                <h3 className="flex-grow-1">Koszyk</h3>
                <button type="button" className="btn btn-outline-secondary rounded-0" onClick={this.clearCart}>Wyczyść koszyk</button>
              </div>
            </div>
            <hr className="my-4"/>
            <div className="my-5">
              <table className="table">
                <thead className="thead-dark">
                <tr>
                  <th className="pl-md-5">Kurs:</th>
                  <th className="pl-md-5">Suma:</th>
                </tr>
                </thead>
                <tbody>
                {lineItems.map(course => {
                  const isInvalid = course.id === alreadyOwnedCourseId || course.id === notAvailableCourseId;
                  return (
                    <tr key={course.id}>
                      <td className="pl-md-5">
                        <div className={className({ "is-invalid": isInvalid })}>{course.title}</div>
                        {course.id === alreadyOwnedCourseId && <RequiredFieldError message="Już posiadasz ten kurs. Usuń go z koszyka."/>}
                        {course.id === notAvailableCourseId && <RequiredFieldError message="Kurs niedostępny"/>}
                      </td>
                      <td className="pl-md-5">{formatPrice(course.price)}</td>
                      <td className="border-0">
                        <button type="button" className="btn btn-primary rounded-0 float-right" onClick={() => this.removeFromCart(course)}>
                          <FontAwesomeIcon icon={faTrash}/>
                        </button>
                      </td>
                    </tr>
                  );
                })}
                </tbody>
                <tfoot>
                <tr>
                  <th>&nbsp;</th>
                  <th className="pl-md-5">{formatPrice(totalPrice)}</th>
                </tr>
                </tfoot>
              </table>
              {!this.context.user && (
                <form onSubmit={this.submitOrder} noValidate>
                  <div className="mt-5">
                    {!wantToPay && submitButton}
                    {!wantToPay && <Link to="/" className="btn btn-outline-info d-block rounded-0">Kontynuuj zakupy</Link>}
                    {wantToPay && <div className="text-center mt-3 mb-3"><Link to="/login">Mam już konto</Link></div>}
                    {wantToPay && <div className="text-center"><Link to="/register">Zakładam nowe konto</Link></div>}
                  </div>
                </form>
              )}
            </div>
            {this.context.user && (
              <form onSubmit={this.submitOrder} noValidate className={className({ 'was-validated': validated })}>
                <div className="mb-5">
                  <div className="form-check">
                    <input type="checkbox" id="termsOfUseConsent" className="form-check-input" checked={termsOfUseConsent}
                           onChange={this.toggleTermsOfUseConsent} required/>
                    <label className="form-check-label pl-3" htmlFor="termsOfUseConsent">Przeczytałem/am i akceptuję <Link
                      to="/terms-of-use" target="_blank">regulamin</Link></label>
                    <RequiredFieldError message="Bez akceptacji regulaminu nie możemy przejść dalej"/>
                  </div>
                  {askForMailingListConsent && (
                    <div className="form-check">
                      <input type="checkbox" id="mailingListConsent" className="form-check-input" checked={mailingListConsent}
                             onChange={this.toggleMailingListConsent}/>
                      <label className="form-check-label pl-3 mt-2" htmlFor="mailingListConsent">Chcę otrzymywać informacje o promocjach i
                        nowościach</label>
                    </div>
                  )}

                  {error && <ExpectedError message={error}/>}
                  {technicalError && <TechnicalError error={technicalError}/>}

                  {submitButton}
                  <Link to="/" className="btn btn-outline-info rounded-0 mb-5 d-block">Kontynuuj zakupy</Link>
                  <img src={PaymentProviderLogo} alt="Przelewy24"/>
                  <div className="text-justify">
                    Administratorem Państwa danych osobowych jest firma ITC Bernard Łabno z siedzibą w Tarnowie przy ul. Szczęśliwej 15.
                    Podanie danych jest dobrowolne, ale niezbędne do zrealizowania zamówienia.
                    Przekazane nam dane osobowe będą przetwarzane w celu obsługi złożonego zamówienia
                    i zostaną usunięte po ustaniu celu ich przetwarzania (w przypadku zaznaczenia opcji pozostania w
                    kontakcie - do czasu wycofania zgody).
                    Mają Państwo prawo dostępu do danych, w tym ich sprostowania, usunięcia lub przeniesienia oraz złożenia skargi do organu
                    nadzorczego.
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    );
  }

  private clearCart() {
    if (window.confirm('Czy napewno wyczyścić koszyk?'))
      this.context.clearCart();
  }

  private removeFromCart(course: CourseDetails) {
    if (window.confirm(`Czy napewno usunąć z koszyka "${course.title}" ?`))
      this.context.removeFromCart(course);
  }

  private async submitOrder(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const { mailingListConsent, termsOfUseConsent } = this.state;
    try {
      this.setState({ wantToPay: true });
      if (!this.context.user) {
        return;
      }
      this.setState({ validated: true });
      if (!termsOfUseConsent) {
        return;
      }
      this.setState({ submitOrderInProgress: true });
      if (this.context.user.mailingListConsent !== mailingListConsent) {
        await this.context.updateMailingListConsent(mailingListConsent);
      }
      const order = await this.context.submitOrder();
      this.props.history.replace(`/orders/${order.id}`);
      await this.context.payOrder(order);
    } catch (e) {
      if (412 === e.status) {
        try {
          const { courseId } = await e.json();
          this.setState({ alreadyOwnedCourseId: courseId, error: 'Już posiadasz jeden z kursów, usuń go z koszyka' });
        } catch (e2) {
          this.setState({ technicalError: await extractTechnicalError(e2) });
        }
      } else if (413 === e.status) {
        try {
          const { courseId } = await e.json();
          this.setState({ notAvailableCourseId: courseId, error: 'Kurs niedostępny' });
        } catch (e2) {
          this.setState({ technicalError: await extractTechnicalError(e2) });
        }
      } else {
        this.setState({ technicalError: await extractTechnicalError(e) });
      }
    } finally {
      this.setState({ submitOrderInProgress: false });
    }
  }

  private toggleMailingListConsent() {
    this.setState(({ mailingListConsent }) => ({ mailingListConsent: !mailingListConsent }));
  }

  private toggleTermsOfUseConsent() {
    this.setState(({ termsOfUseConsent }) => ({ termsOfUseConsent: !termsOfUseConsent }));
  }
}

Checkout.contextType = DefaultBusinessContext;

export default withRouter(Checkout);
