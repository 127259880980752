import React, { useContext, useRef, useState } from 'react';
import DefaultBusinessContext from '../../business/BusinessContext';
import { Link, useHistory, useParams } from 'react-router-dom';
import RequiredFieldError from '../RequiredFieldError';
import { className, extractTechnicalError, TechnicalErrorDetails } from '../../util';
import TechnicalError from '../TechnicalError';
import ExpectedError from '../ExpectedError';

type ResetPasswordFormProps = {
  setInvalidToken: (value: boolean) => void
  setTechnicalError: (e: TechnicalErrorDetails) => void
};
const ResetPasswordForm = ({ setInvalidToken, setTechnicalError }: ResetPasswordFormProps) => {
  const { token } = useParams();
  const context = useContext(DefaultBusinessContext);
  const history = useHistory();
  const [validated, setValidated] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [passwordConfirmationError, setPasswordConfirmationError] = useState('');
  const [error, setError] = useState('');
  const passwordConfirmationRef = useRef(null);

  const resetPassword = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setValidated(true);
    setPasswordConfirmationError('');
    setError('');
    setTechnicalError(null as unknown as TechnicalErrorDetails);
    let hasErrors = false;
    if (newPassword !== passwordConfirmation) {
      const error = 'Hasła się nie zgadzają';
      setPasswordConfirmationError(error);
      // @ts-ignore
      passwordConfirmationRef.current.setCustomValidity(error);
      hasErrors = hasErrors || true;
    } else {
      // @ts-ignore
      passwordConfirmationRef.current.setCustomValidity('');
    }
    if (hasErrors || !event.currentTarget.checkValidity()) {
      return;
    }
    try {
      await context.resetPassword(token, newPassword);
      setNewPassword('');
      setPasswordConfirmation('');
      setValidated(false);
      history.replace('/')
    } catch (e) {
      if (401 === e.status) {
        setInvalidToken(true);
      } else {
        setTechnicalError(await extractTechnicalError(e));
      }
    }
  };
  return (
    <form onSubmit={resetPassword} noValidate className={className({ 'was-validated': validated })}>
      <div className="form-group">
        <label htmlFor="newPassword">Nowe hasło</label>
        <input type="password" className="form-control" id="newPassword" minLength={6} required value={newPassword}
               onChange={e => setNewPassword(e.target.value)}/>
        <RequiredFieldError message={'Hasło musi mieć conajmniej 6 znaków'}/>
      </div>
      <div className="form-group">
        <label htmlFor="repeatNewPassword">Powtórz nowe hasło</label>
        <input ref={passwordConfirmationRef} type="password"
               className={className('form-control', { 'is-invalid': passwordConfirmationError })} id="repeatNewPassword" required
               value={passwordConfirmation} onChange={e => setPasswordConfirmation(e.target.value)}/>
        <RequiredFieldError message={passwordConfirmationError}/>
      </div>
      {error && (<ExpectedError message={error}/>)}
      <button type="submit" className="btn btn-primary rounded-0 w-100 my-3">Zmień hasło</button>
    </form>
  );
};

const InvalidTokenError = () => (
  <div>
    <ExpectedError message={'Link do zmiany hasła wygasł'}/>
    <Link to="/forgot-password" className="btn btn-primary w-100 my-3">Rozpocznij reset hasła od nowa</Link>
  </div>
);

const ResetPassword = () => {
  const [invalidToken, setInvalidToken] = useState(false);
  const [technicalError, setTechnicalError] = useState(null as unknown as TechnicalErrorDetails);
  return (
    <div className="container reset-password">
      <div className="row">
        <div className="col-12 col-md-6 offset-md-3 mt-5">
          <h3>Zmiana hasła</h3>
          <hr className="my-4"/>
          {technicalError && <div className="col-12"><TechnicalError error={technicalError}/></div>}
          {invalidToken && <InvalidTokenError/>}
          {!invalidToken && <ResetPasswordForm setInvalidToken={setInvalidToken} setTechnicalError={setTechnicalError}/>}
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
