import React from 'react';
import { className } from '../../util';

type ExpectedErrorProps = {
  center?: boolean,
  message: string,
}
const ExpectedError = ({ center, message }: ExpectedErrorProps) => (
  <div className={className('alert alert-danger', { 'text-center': center })} role="alert">{message}</div>
);
export default ExpectedError;
