import React from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import DefaultBusinessContext from '../../business/BusinessContext';
import { className, extractTechnicalError, TechnicalErrorDetails } from '../../util';
import TechnicalError from '../TechnicalError';
import ExpectedError from '../ExpectedError';
import RequiredFieldError from '../RequiredFieldError';
import LaddaButton from 'react-ladda';

type LoginProps = RouteComponentProps;

type LoginState = {
  email?: string,
  error?: string,
  loginInProgress: boolean,
  validated: boolean,
  password?: string,
  technicalError?: TechnicalErrorDetails
};

class Login extends React.Component<LoginProps, LoginState> {

  constructor(props: LoginProps) {
    super(props);
    this.login = this.login.bind(this);
    this.onEmailChange = this.onEmailChange.bind(this);
    this.onPasswordChange = this.onPasswordChange.bind(this);
    this.state = { loginInProgress: false, validated: false };
  }

  render() {
    const { error, loginInProgress, technicalError, validated } = this.state;
    return (
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6 offset-md-3 my-5">
            <h3>Zaloguj się</h3>
            <hr className="my-4"/>
            <form onSubmit={this.login} noValidate className={className({ 'was-validated': validated })}>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input type="email" className="form-control" id="email" onChange={this.onEmailChange} required/>
                <RequiredFieldError message={'Wymagany poprawny email'}/>
              </div>
              <div className="form-group">
                <label htmlFor="exampleInputPassword1">Hasło</label>
                <input type="password" className="form-control" id="exampleInputPassword1" onChange={this.onPasswordChange} required/>
                <RequiredFieldError/>
              </div>

              {error && <ExpectedError message={error}/>}
              {technicalError && <TechnicalError error={technicalError}/>}

              <LaddaButton loading={loginInProgress} className="btn btn-primary rounded-0 w-100 mb-5">Zaloguj</LaddaButton>
            </form>
            <div className="text-center mb-3"><Link to="/forgot-password">Nie pamiętam hasła</Link></div>
            <div className="text-center">Nie masz konta? <Link to="/register">Zarejestruj się</Link></div>
          </div>
        </div>
      </div>
    );
  }

  async login(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const { email, password } = this.state;
    this.setState({ validated: true, error: undefined, technicalError: undefined });
    if (!email || !password) {
      return;
    }
    this.setState({ loginInProgress: true });
    try {
      await this.context.login(email, password);
      const redirectTo = this.context.isCartEmpty() ? '/' : '/checkout';
      this.props.history.replace(redirectTo);
    } catch (e) {
      if (401 === e.status) {
        this.setState({ error: 'Błędny email lub hasło' });
      } else {
        this.setState({ technicalError: await extractTechnicalError(e), });
      }
    } finally {
      this.setState({ loginInProgress: false });
    }
  }

  onEmailChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ ...this.state, email: event.currentTarget.value });
  }

  onPasswordChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ ...this.state, password: event.currentTarget.value });
  }

}

Login.contextType = DefaultBusinessContext;

export default withRouter(Login);
