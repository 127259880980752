import * as DataModel from '../../business/DataModel';
import { Link } from 'react-router-dom';
import React from 'react';
import { className, formatPrice } from '../../util';
import './CourseListItem.scss';

type CourseListItemProps = { course: DataModel.CourseListItem }
export const CourseListItem = ({ course }: CourseListItemProps) => {
  return (
    <div className="col-12  col-sm-6 col-md-4 col-lg-3 mb-3" key={course.id}>
      <div className="card card-shadow">
        <img src={course.thumbnail} className="card-img-top" alt="..."/>
        <div className="card-body">
          <h5 className="card-title"><Link to={`/course/${course.id}`}>{course.title}</Link></h5>
          <p className="card-text">{course.summary}</p>
          {course.available && (
            <>
              {!course.owned && !course.redirectUrl &&
                <div className={className('font-weight-bold mb-2', { 'free-course': course.free })}>{formatPrice(course.price)}</div>}
              {!course.free && !course.owned && <Link to={`/course/${course.id}`} className="btn btn-primary">Kup teraz</Link>}
              {(course.free || course.owned) && <Link to={`/course/${course.id}`} className="btn btn-info">Szczegóły</Link>}
            </>
          )}
          {!course.available && (
            <div className="badge badge-secondary">W przygotowaniu</div>
          )}
        </div>
      </div>
    </div>
  );
};
