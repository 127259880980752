import React, { useContext, useEffect, useState } from 'react';

import { createTechnicalErrorHandler, formatDate, formatPrice, formatStatus, TechnicalErrorDetails } from '../../util';
import DefaultBusinessContext from '../../business/BusinessContext';
import { Link, useParams } from 'react-router-dom';
import * as DataModel from '../../business/DataModel';
import TechnicalError from '../TechnicalError';
import LaddaButton from 'react-ladda';
import Loading from '../Loading'

const OrderDetails = () => {
  const context = useContext(DefaultBusinessContext);
  const { orderId } = useParams();
  const [order, setOrder] = useState(null as unknown as DataModel.Order);
  const [error, setError] = useState(null as unknown as TechnicalErrorDetails);
  const [payInProgress, setPayInProgress] = useState(false);
  useEffect(() => {
    if (null == order || order.id !== orderId) {
      setError(null as unknown as TechnicalErrorDetails);
      context.getOrder(orderId)
        .then(setOrder)
        .catch(createTechnicalErrorHandler(setError))
    }
  }, [order, context, orderId]);
  if (null != error) {
    return (
      <div className="container">
        <div className="mt-5">
          <TechnicalError error={error}/>
        </div>
      </div>
    )
  }
  if (null == order) {
    return (
      <div className="container">
        <Loading/>
      </div>
    );
  }
  const pay = () => {
    setPayInProgress(true);
    return context.payOrder(order);
  };
  return (
    <div className="container order-details">
      <div className="row">
        <div className=" col-12 col-lg-6 offset-lg-3 order-1 order-lg-2">
          <h3 className="mt-5">Zamówienie {order.id}</h3>
          <hr/>
          <div className="font-weight-bold mt-3">Data zamówienia: {formatDate(order.createDate)}</div>
          <div className="font-weight-bold mt-3">Status zamówienia: {formatStatus(order.status)}</div>
          <table className="table mt-3">
            <thead className="thead-dark">
            <tr>
              <th className="pl-md-5">Kurs</th>
              <th>Suma</th>
            </tr>
            </thead>
            <tbody>
            {order.lineItems.map(course => (
              <tr key={course.id}>
                <td className="pl-md-5">
                  <Link to={`/course/${course.id}`}>{course.title}</Link>
                </td>
                <td>{formatPrice(course.price)}</td>
              </tr>
            ))}
            </tbody>
            <tfoot>
            <tr>
              <th>&nbsp;</th>
              <th>{formatPrice(order.totalPrice)}</th>
            </tr>
            </tfoot>
          </table>
          {'created' === order.status &&
          <LaddaButton loading={payInProgress} className="btn btn-primary rounded-0 w-25 mt-5" onClick={pay}>Płacę</LaddaButton>}
        </div>
      </div>
    </div>
  );
}

export default OrderDetails;
