import React from 'react';
import { TechnicalErrorDetails } from '../../util';

type TechnicalErrorProps = {
  error: TechnicalErrorDetails
}

const TechnicalError = ({ error }: TechnicalErrorProps) => (
  <div className="alert alert-danger text-center py-5" role="alert">
    <div>Wystąpił nieznany błąd, skontaktuj się z administratorem.</div>
    {error.message && <div>{error.message}</div>}
    {error.status && <div>Status: {error.status}</div>}
    {error.body && <div>Body: {error.body}</div>}
  </div>
);
export default TechnicalError;
